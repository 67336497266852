import toast from "react-hot-toast";

export const throwError = (err) => {
    let status;
    let message;
    // if(err.response?.status === 204) return null
    if(!err.response?.status) return null;
    if(err){
        const {response} = err
        status = response.status
        message = response.data.message

        if(status >= 400 && status <= 499){
            if(status === 401 || status === 403){
                // handle unauthorized or forbidden error
            }
            toast.error(message);
        }else if(status >=500 && status <= 599){
            toast.error('Internal Server Error');
        }
    }
};